<template>
  <content justify="center" class="">
    <!-- DIALOGO RESPUESTA INGRESO PEDIDOS -->
    <!-- FIN DIALOGO RESPUESTA INGRESO PEDIDOS -->
    <v-form ref="enviarFormulario" v-model="validar">
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" sm="8" md="7" lg="7" xl="7">
          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <h3>
                <v-icon size="50" color="info">mdi-barcode-scan</v-icon>
                Escaneo de Pedidos
              </h3>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <v-autocomplete
                v-model="clienteSeleccionado"
                :items="items"
                label="Selecciona cliente"
                class="mt-3"
                :rules="[(v) => !!v || 'Campo obligatorio']"
                solo
                :loading="cargandoListaClientes"
                required
                @change="emailsClientes"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" sm="8" md="5" lg="5">
          <v-row justify="center">
            <v-text-field
              :disabled="!validar"
              v-model="order_id"
              style="align-items: center; justify-content: center; height: 10vh"
              class="text-white pr-10"
              color="white"
              label="CÓDIGO DE BARRAS"
              prepend-icon="mdi-qrcode-scan"
              @change="consultaPedido()"
              autofocus
            ></v-text-field>

            <v-img
              v-model="check"
              v-if="this.check == true"
              class="pa-7 secondary rounded-circle d-inline-block"
              max-width="60"
              elevation="12"
              height="60"
              width="100%"
              src="https://static.vecteezy.com/system/resources/previews/001/200/277/large_2x/check-png.png"
            ></v-img>

            <v-img
              v-model="errorCheck"
              v-if="this.errorCheck == true"
              class="pa-7 secondary rounded-circle d-inline-block"
              max-width="60"
              elevation="12"
              height="60"
              width="100%"
              src="https://cdn.icon-icons.com/icons2/1380/PNG/512/vcsconflicting_93497.png"
            ></v-img>
          </v-row>

          <br />
          <v-card>
            <!-- <v-card-title class="info lighten-1 white--text">
              <strong>RESULTADO</strong>
              <v-progress-circular
                class="ml-2"
                
                :color="colorProgreso"
              ></v-progress-circular>
            </v-card-title> -->

            <div class="container">
              <v-row>
                <v-col>
                  <v-simple-table
                    :loading="cargandoPedidos"
                    dense
                    class="success"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>PERTENECE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in itemsExitosos"
                          :key="item.orderIdExitoso"
                        >
                          <td>{{ item }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col>
                  <v-simple-table dense class="error">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" colspan="2">NO PERTENECE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in itemsFallidos"
                          :key="item.orderIdFallido"
                        >
                          <td>{{ item }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <br />
          <v-row justify="center">
            <v-btn @click="enviarCorreo(true)" color="success" class="mr-10">
              Enviar retiros
            </v-btn>
            <v-btn @click="enviarCorreo(false)" color="error">
              Enviar faltantes
            </v-btn>
            <!-- <v-btn @click="obtenerPedidosCorreo">CLIUCJ</v-btn> -->
            <v-dialog v-model="dialogOK" persistent max-width="560">
              <v-card>
                <v-card-title class="headline">PROCESO FINALIZADO</v-card-title>
                <v-card-text>
                  <v-alert type="success">EXITO!</v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="dialogOK = false">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-alert v-model="correoFallido" type="error"></v-alert>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </content>
</template>

<script>
import axios from "axios";
import firebase from "firebase";

export default {
  data() {
    return {
      order_id: "",
      rayoRules: [(v) => !!v || "Debes seleccionar Rayo"],
      pedidosRules: [(v) => !!v || "Debes ingresar al menos un pedido"],
      items: ["CORONA", "TRAVEL"],
      correosCorona: ["gaston@rayoapp.com", "gastonviotti1987@gmail.com"],
      listaRayos: [],
      clientes: [],
      pedidos: null,
      rayoSeleccionado: "",
      clienteSeleccionado: "",
      listaPedidos: "",
      dialogoRespuestaIngreso: false,
      colorProgreso: "",
      validar: false,
      cargandoListaRayos: false,
      cargandoListaClientes: false,
      cargandoPedidos: false,
      correoExito: false,
      correoFallido: false,
      check: false,
      errorCheck: false,
      dialogOK: false,
      fechaActual: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      itemsFallidos: [],
      itemsExitosos: [],
      estadoCorreo: null,
    };
  },
  computed: {
    async obtenerRayos() {
      try {
        console.log("Obteniendo Rayos...");
        this.cargandoListaRayos = true;
        let arrayFleets = [];
        const listaRayos = await axios.get(
          "https://backendservices.rayoapp.com/Rayos/rayos/reduced",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );

        for (const n in listaRayos.data) {
          arrayFleets.push({
            text: listaRayos.data[n].name_rayo,
            id: listaRayos.data[n].id,
          });
        }

        console.log(listaRayos.status);

        listaRayos.status == 200
          ? console.log("Lista creada!")
          : console.log("No se logró obtener la lista! Revisar API");

        arrayFleets.sort((b, a) => b.text.localeCompare(a.text));
        this.listaRayos = arrayFleets;

        this.cargandoListaRayos = false;
      } catch (error) {
        this.cargandoListaRayos = false;
        console.log(error);
      }
    },
  },

  methods: {
    async listarClientes() {
      try {
        this.cargandoListaClientes = true;
        console.log("Obteniendo Clientes...");
        this.cargandoListaClientes = true;
        const respuestaClientes = await axios.get(
          "https://backendservices.rayoapp.com/Customers/customers",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );

        this.clientes = respuestaClientes.data;
        this.cargandoListaClientes = false;
        console.log("Clientes listados!");
      } catch (error) {
        console.log(error);
      }
    },

    //llamada a
    datosUsuario () {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid
      }
    },
    validarFormulario() {
      this.$refs.enviarFormulario.validate();
    },

    //CONSULTAMOS PEDIDOS EN THOR
    async consultaPedido() {
      const respuesta = await axios.get(
        `https://backendservices.rayoapp.com/Task-Groups/tasks_group/aux/by?idOrder=${this.order_id}`,
        {
          headers: {
            "warehouse.rayoapp.com": "rayo",
          },
        }
      );
      console.log(respuesta, "BUSCANDO...");
      console.log("No se encuentra pedido en THOR!");
      var pedido = this.order_id;

      if (respuesta.data.length == 0) {
        this.errorCheck = true;
        setTimeout(() => {
          this.errorCheck = false;
        }, 3000);
        this.order_id = "";

        const statusFalse = await this.updatePedidosCorreo(
          this.datosUsuario().email_user,
          pedido,
          false
        );

        if (statusFalse == 200) {
          this.obtenerPedidosCorreo();
        }
      } else {
        this.check = true;
        console.log("Datos obtenidos!");
        setTimeout(() => {
          this.check = false;
        }, 3000);

        const statusTrue = await this.updatePedidosCorreo(
          this.datosUsuario().email_user,
          pedido,
          true
        );

        if (statusTrue == 200) {
          this.obtenerPedidosCorreo();
        }

        this.order_id = "";
      }
    },

    async obtenerPedidosCorreo() {
      this.itemsExitosos = [];
      this.itemsFallidos = [];
      this.cargandoPedidos = true;
      let bodyUsuario = {
        rayo: this.datosUsuario().email_user,
        
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
      };

      const respuestaMongo = await axios.post(
        "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/pedidos_correo",
        bodyUsuario
      );
      console.log("RESPUESTA MONGO", respuestaMongo);
      for (const i in respuestaMongo.data.pedidos.pertenece) {
        this.itemsExitosos.push(respuestaMongo.data.pedidos.pertenece[i]);
      }

      for (const i in respuestaMongo.data.pedidos.noPertenece) {
        this.itemsFallidos.push(respuestaMongo.data.pedidos.noPertenece[i]);
      }
      this.cargandoPedidos = false;
      console.log("Lista completa");
    },
    async updatePedidosCorreo(rayo, order_id, estado) {
      let bodyUpdate = {
        rayo_correo: rayo,
        pedido: order_id,
        estado: estado,
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
      };

      const respuestaMongo = await axios.post(
        "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/update_correo",
        bodyUpdate
      );
      console.log("Update:", respuestaMongo);

      return respuestaMongo.status;

      // for (const i in respuestaMongo.data[0].rayos[0].pedidos.pertenece) {
      //   this.itemsExitosos.push(
      //     respuestaMongo.data[0].rayos[0].pedidos.pertenece[i]
      //   );
      // }
    },
    async enviarCorreo(arrayPedidos, arrayCorreos) {
      if (arrayPedidos) {
        let bodyCorreo = {
          correos: this.correosCorona,
          pedidos: this.itemsExitosos,
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
        };
        console.log("body", bodyCorreo);
        const respuestaCorreo = await axios.post(
          "https://us-central1-warehouse-337418.cloudfunctions.net/correos/retiros",
          bodyCorreo
        );

        console.log("SENDGRID:", respuestaCorreo);
        this.estadoCorreo = respuestaCorreo;
      } else {
        let bodyCorreo = {
          correos: this.correosCorona,
          pedidos: this.itemsFallidos,
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
        };
        console.log("body", bodyCorreo);
        const respuestaCorreo = await axios.post(
          "https://us-central1-warehouse-337418.cloudfunctions.net/correos/faltantes",
          bodyCorreo
        );

        console.log("SENDGRID:", respuestaCorreo.status);
        this.estadoCorreo = respuestaCorreo;
      }

      if (this.estadoCorreo.status == "201") {
        this.dialogOK = true;
      } else {
        console.log("NO ES CORONA");
      }
    },
    async emailsClientes() {
      let bodyClient = {
        // cliente: this.clienteSeleccionado,
        cliente: "60abad20ce2a1719d870c3e6",
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
      };

      const emailMongo = await axios.post(
        "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/clients_emails",
        bodyClient
      );
      console.log("EMAIL:", emailMongo);

      // for (const i in respuestaMongo.data[0].rayos[0].pedidos.pertenece) {
      //   this.itemsExitosos.push(
      //     respuestaMongo.data[0].rayos[0].pedidos.pertenece[i]
      //   );
      // }
    },
    mounted() {
      this.obtenerPedidosCorreo();
      this.obtenerRayos;
      this.datosUsuario();
      this.listarClientes();
    },
  },
};
</script>
